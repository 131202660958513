//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters, mapState} from 'vuex'

export default {
  layout: 'frontend/default',
  middleware({$auth, $toast, route, redirect, store}) {
    const ownerCompany = store.getters[
      'common/hardcodedValue/getOwnerCompanyHardcodedValue'
    ]()
    if (
      ownerCompany &&
      ownerCompany.value !== 'Thumbies' &&
      ownerCompany.value !== 'SUPERFIT' &&
      ownerCompany.value !== 'Kuczora' &&
      ownerCompany.value !== 'WHITEHURST'
    ) {
      if (route.path === '/shopping-cart' && !$auth.loggedIn) {
        if ($toast) {
          $toast.info(
            'You need to log in first to have access to the shopping cart!'
          )
        }
        redirect('/user-login')
      }
      if (
        (route.path === '/user-registration' || route.path === '/user-login') &&
        $auth.loggedIn
      ) {
        redirect('/home')
      }
    }
  },
  data() {
    return {
      showBackToTop: false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async asyncData(context) {
    const version = context.query.hcms || context.isDev ? 'draft' : 'published'
    const fullSlug =
      context.route.path === '/' || context.route.path === ''
        ? 'home'
        : context.route.path
    // Get data from API

    const contentData = (
      await context.$axios.get(`/contents/${fullSlug.replace(/^\/+/g, '')}`, {
        // /catch
        params: {version},
      })
    ).data
    const redirectToDefaultPage = (redirectUrl) =>{
      if(redirectUrl) {
        if (process.client) {
          window.location.href = redirectUrl
        } else {
          context.redirect(redirectUrl)
        }
      }
    }
    if (!contentData.id) {
      const ownerCompany = context.store.getters[
        'common/hardcodedValue/getOwnerCompanyHardcodedValue'
      ]()
      if (ownerCompany) {
        switch(ownerCompany.value){
          case 'Thumbies':
            context.error({statusCode: 404, message: 'Page not found'})
            return
          case 'SUPERFIT':
            redirectToDefaultPage('/home')
            return
          case 'WHITEHURST':
            redirectToDefaultPage('/catalog')
            return
        }
      }

      redirectToDefaultPage('/backoffice/dashboard')
    }

    return {
      text: 'hello hello',
      contentData,
      /* content: {
        uid: 'asfdsafdsafda',
        publishedAt: '2021-07-25',
        createdAt: '2021-07-02',
        slug: 'home',
        fullSlug: 'en/home',
        position: 0,
        parentId: null,
        language: 'default',
        translatedSlugs: [],
        details: {
          uid: 111,
          component: 'page',
          body: [
            {
              component: 'layout-header',
              fields: {
                title: 'Sitename',
              },
              uid: 1,
            },
            {
              component: 'layout-slider',
              fields: {},
              uid: 2,
            },
            {
              component: 'ui-post-posts',
              fields: {},
              uid: 3,
            },
            {
              component: 'layout-footer',
              fields: {},
              uid: 4,
            },
          ],
        },
      }, */
    }
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({addSeoAttributes: true})
    return {
      title: 'Home',
      meta: [
        // hid is used as unique identifier. Do not use `vmid` for it as it will not work
        {
          hid: 'description',
          name: 'description',
          content:
            this.contentData &&
            this.contentData.details &&
            this.contentData.details.description &&
            this.contentData.details.description.fieldValue &&
            this.contentData.details.description.fieldValue.value,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content:
            this.contentData &&
            this.contentData.details &&
            this.contentData.details.keywords &&
            this.contentData.details.keywords.fieldValue &&
            this.contentData.details.keywords.fieldValue.value,
        }, // Add og meta stuff also, site thumbnail
        ...i18nHead.meta,
      ],
    }
  },
  async mounted() {
    window.addEventListener('scroll', this.handleScroll);
    // console.log('route ', this.$route)
    // this.contentData = (await this.$axios.get('/contents/xyz')).data
    // console.log('contentData', this.contentData)
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('common/hardcodedValue', ['getOwnerCompanyHardcodedValue']),
    ...mapState('common/hardcodedValue', ['hardcodedValues']),
  },
  methods:{
    /*redirectToDefaultPage(redirectUrl, context){
      if(redirectUrl) {
        if (process.client) {
          window.location.href = redirectUrl
        } else {
          //context.redirect(redirectUrl)
        }
      }
    },*/
    handleScroll() {
      const scrollTop = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      this.showBackToTop = scrollTop > (documentHeight - windowHeight) / 1.5;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
}
